import uploadFile from "../../resources/js/utils/uploadFile";

window.addEventListener('load', () => {
    const uploadFiles = document.querySelectorAll('.gfield--type-fileupload');
    if (uploadFiles.length > 0) {
        uploadFiles.forEach((file) => {
            uploadFile(file);
        })
    }
});
