const uploadFile = (element) => {
    element.addEventListener('change', () => {
        const file = element.querySelector('[type="file"]');
        const fileName = file.files[0]?.name;
        const label = element.querySelector('.gform-field-label');
        label.style.backgroundColor = "#fff";
        label.innerText = fileName ?? "";
    })
}

export default uploadFile;